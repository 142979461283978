"use client";

import { useLayoutEffect, useState } from "react";
import apiClient from "@/apiClient";
import { API_URL } from "../../../constants";
import userStore from "@/store";
import { UserStatuses } from "@/lib/types";

export default function InitialRender({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [isMounted, setIsMounted] = useState(false);
  const { setUser, setUserStatus, setCustomFields, setPlans, setNoNotesData } =
    userStore();

  const getMe = async () => {
    try {
      const response = await apiClient.get(`${API_URL}/customer/me`);
      setUser(response.data);
      setUserStatus(response.data.status);
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 403) {
        setUserStatus(UserStatuses.EXPIRED);
        localStorage.removeItem("token");
      }
    } finally {
      setIsMounted(true);
    }
  };

  const getCustomFields = async () => {
    try {
      const response = await apiClient.get(`${API_URL}/customer/me/fields`);

      setCustomFields(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPlans = async () => {
    try {
      const response = await apiClient.get(
        `${API_URL}/customer/billings/plans`
      );
      setPlans(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const checkIfNotesDataExists = async () => {
    try {
      const response = await apiClient.get(`${API_URL}/customer/note`, {
        params: { limit: 1, offset: 0 },
      });
      setNoNotesData(response.data.data.length === 0);
    } catch (error) {
      console.error(error);
      setNoNotesData(true);
    }
  };

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const promises = [getMe()];
        if (token) {
          promises.push(getCustomFields());
          promises.push(getPlans());
          promises.push(checkIfNotesDataExists());
        }
        await Promise.all(promises);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return isMounted && children
}
